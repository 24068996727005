export const getUserSchools = user => {
  return user?.attributes?.profile?.protectedData?.schools;
};

export const getPreferredSchools = user => {
  return user?.attributes?.profile?.publicData?.preferredSchools;
};

/**
 * Converts an array of school names into the documentation format.
 *
 * @param {string[]} schoolArray - Array of school names.
 * @returns {string} - Formatted string in the form of pub_ATTRIBUTE_KEY=has_any:VALUE1[,VALUE2[,...]]
 */
export const convertToDocumentationFormat = schoolArray => {
  // Ensure the input is a valid array
  if (!Array.isArray(schoolArray) || schoolArray.length === 0) {
    return '';
  }

  // Join the array elements into a comma-separated string
  const joinedSchools = schoolArray.join(',');

  // Format the string according to the documentation
  return `has_any:${joinedSchools}`;
};

export const getListingType = l => {
  return l?.attributes?.publicData?.listingType;
};

export const getDonationParams = l => {
  return l?.attributes?.publicData?.donationParams;
};
