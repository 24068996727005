import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import css from './AutoSuggestion.module.css';
import { isArrayLength } from '../../util/genericHelpers';
import classNames from 'classnames';

const AutoSuggestion = ({
  data,
  searchKeys,
  onSelectionChange,
  initialValues,
  isSchoolFilter,
  isMulti = true,
  isPreferredSchools,
  isEditListingAuto,
}) => {
  const [formattedData, setFormattedData] = useState([]);
  const [initialOptions, setInitialOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const formattedOptions = data.map(item => ({
      value: item[searchKeys[0]],
      label: searchKeys.map(key => (item[key] ? item[key].toString() : '')).join(' - '),
    }));
    setFormattedData(formattedOptions);
  }, [data, searchKeys]);

  useEffect(() => {
    if (isArrayLength(initialValues) && formattedData.length > 0) {
      const initialOptions = initialValues.map(value => {
        const option = formattedData.find(option => option.value === value?.value);
        return option || value;
      });
      setInitialOptions(initialOptions);
      setSelectedOptions(initialOptions);
    }
  }, [formattedData, initialValues]);

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      const filteredOptions = formattedData.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredOptions);
    }, 500); // Add a delay to simulate async loading (optional)
  };

  const handleChange = options => {
    setSelectedOptions(options || []);
    onSelectionChange(options);
  };
  const customStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#fff',
        border:
          isSchoolFilter && isFocused ? `border:solid 1px #d8dce6` : `border:solid 1px #d8dce6`,
        boxShadow: 'none',
        borderRadius: '6px',
        padding: '0',
        margin: '0',
        Height: '40px',
        minHeight: '40px',
        cursor: 'pointer',
        '&:hover': {
          border: isSchoolFilter ? `solid 1px #b8bfd1` : `solid 1px #b8bfd1`,
        },
        '&:focus': {
          border: isSchoolFilter ? `solid 1px #b8bfd1` : `solid 1px #b8bfd1`,
        },
        '&:active': {
          border: isSchoolFilter ? `solid 1px #b8bfd1` : `solid 1px #b8bfd1`,
        },
      };
    },
    placeholder: styles => ({
      ...styles,
      position: 'relative',
      bottom: '0px',
      padding: '0px 10px',
      margin: '0',
      fontSize: isSchoolFilter ? '13px' : '16px',
      lineHeight: '120%',
    }),
    valueContainer: styles => ({
      ...styles,
      minHeight: '40px',
      padding: '0 10px 0 3px',
      margin: '0',
      fontWeight: '400',
      fontSize: isSchoolFilter ? '14px' : '16px',
      lineHeight: '120%',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? '#ddd' : '#fff',
        color: isDisabled ? '#ccc' : '#313135',
        fontSize: isSchoolFilter ? '14px' : '16px',
        fontWeight: '400',
        lineHeight: '120%',
        padding: '10px 16px',
        cursor: isDisabled ? 'not-allowed' : 'default',
        '&:hover': {
          backgroundColor: '#eee',
          color: '#000',
          cursor: 'pointer',
        },
      };
    },
    input: styles => ({
      ...styles,
      margin: '0px',
      padding: '0px 10px',
      fontSize: isSchoolFilter ? '14px' : '16px',
      fontWeight: '400',
      lineHeight: '120%',
    }),
    menu: styles => ({
      ...styles,
      backgroundColor: '#fff',
    }),
  };

  return (
    <div
      className={classNames(
        isSchoolFilter
          ? css.filterPredictionRoot
          : isPreferredSchools
          ? css.preferredSchools
          : css.predictionRoot
      )}
    >
      <AsyncSelect
        loadOptions={loadOptions}
        onChange={handleChange}
        placeholder={isEditListingAuto ? 'Select School' : 'Select School to find items for sale'}
        isClearable
        cacheOptions
        isMulti={isMulti}
        defaultOptions={formattedData.slice(0, 200)} // Show the first 200 options by default
        value={selectedOptions} // Set selected options
        styles={customStyles}
        components={{
          IndicatorSeparator: () => null,
        }}
        className={isSchoolFilter ? css.customFilterSelect : css.customSelect}
      />
    </div>
  );
};

export default AutoSuggestion;
