import { useCallback, useRef, useState } from 'react';
import { PROCESSES } from '../transactions/transaction';
import { EXTRA_CLOTHES_EQUIMENT } from './enums';

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export const isWindowDefined = () => {
  return typeof window !== 'undefined';
};

export function isFunction(value) {
  return typeof value === 'function';
}

export const useStateRef = initialState => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  const dispatch = useCallback(stateToSet => {
    ref.current = isFunction(stateToSet) ? stateToSet(ref.current) : stateToSet;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

export const renameObjKeyToValue = arr => {
  return arr.map(item => {
    const { key, ...rest } = item;
    return { value: key, ...rest };
  });
};

export const capitalizeFirstLetter = string => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getKeyFromObject = (obj, keyFragment) => {
  // Convert the key fragment to lowercase for case-insensitive search
  const lowerCaseKeyFragment = keyFragment.toLowerCase();

  // Iterate over the keys of the object
  for (let key in obj) {
    if (key.toLowerCase().includes(lowerCaseKeyFragment)) {
      const value = obj[key];
      // Check if the value is an array, and return the first item if it is
      if (Array.isArray(value)) {
        return value.length > 0 ? value[0] : null;
      }
      // Return the value if it is not an array
      return value;
    }
  }

  // Return null if no key is found
  return '';
};

export const getSuburbFromAddress = address => {
  if (!address) {
    return null;
  }

  // List of all Australian states and territories
  const statesAndTerritories = [
    'New South Wales',
    'Victoria',
    'Queensland',
    'Western Australia',
    'South Australia',
    'Tasmania',
    'Australian Capital Territory',
    'Northern Territory',
  ];

  // Create a regex pattern to match any state or territory
  const statePattern = new RegExp(statesAndTerritories.join('|'), 'i');

  // Split the address into parts
  const parts = address.split(',');

  // Suburbs are typically before the state
  for (let i = 0; i < parts.length; i++) {
    const trimmedPart = parts[i].trim();

    // Check if the part contains a state
    const isState = statePattern.test(trimmedPart);

    // Return the previous part if the current part is a state
    if (isState && i > 0) {
      return parts[i - 1].trim();
    }
  }

  return null;
};

export const getProcessNameByListingAlias = transactionProcessAlias => {
  const process = PROCESSES.find(p => p.alias === transactionProcessAlias);
  return process ? process.name : null;
};

export const appendOptionalTag = (label, name, listingType) => {
  const isEquipments = listingType === EXTRA_CLOTHES_EQUIMENT && name === 'pub_gender';
  const labels = ['Academic Stage', 'Gender', 'Academic Year'];
  return labels.includes(label) ? `${label} (optional)` : label;
};

export const createExtraSearchFilters = (enumOptions, key, label) => {
  return [
    {
      key,
      scope: 'public',
      schemaType: 'enum',
      enumOptions,
      filterConfig: {
        indexForSearch: true,
        label,
        filterType: 'SelectMultipleFilter',
        group: 'primary',
      },
      showConfig: {
        label,
        isDetail: true,
        unselectedOptions: true,
      },
      saveConfig: {
        label,
        isRequired: true,
      },
      categoryConfig: {
        limitToCategoryIds: false,
      },
      listingTypeConfig: {
        limitToListingTypeIds: false,
      },
    },
  ];
};
