import schoolsData from '../assets/schoolData.json';
import { getPreferredSchools } from './dataExtractors';
import { isArrayLength } from './genericHelpers';

export const signupAgeCategoryOptions = [
  { key: '0-2-years', value: '0-2-years' },
  { key: '3-5-years', value: '3-5-years' },
  { key: '6-10-years', value: '6-10-years' },
  { key: '11-15-years', value: '11-15-years' },
  { key: '16-20-years', value: '16-20-years' },
];

export const IS_DONATION = 'yes';
export const SCHOOL_UNIFORM = 'school_uniforms';
export const BOOKS = 'books';
export const EXTRA_CLOTHES_EQUIMENT = 'rec_items';

export const schoolsOptions = currentUser => {
  const preferredSchools = getPreferredSchools(currentUser);

  const formattedSchoolOptions = schoolsData.map(item => ({
    key: item,
    label: item,
  }));

  const enumOptions =
    (isArrayLength(preferredSchools) &&
      preferredSchools.map(item => ({
        key: item,
        label: item,
      }))) ||
    formattedSchoolOptions;

  return {
    key: 'preferredSchools',
    scope: 'public',
    schemaType: 'school',
    enumOptions,
    filterConfig: {
      indexForSearch: true,
      label: 'Schools',
      filterType: 'SelectMultipleFilter',
      group: 'primary',
    },
    showConfig: {
      label: 'Schools',
      isDetail: true,
    },
    saveConfig: {
      label: 'Schools',
      isRequired: true,
    },
    includeForListingTypes: [],
  };
};
